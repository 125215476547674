import * as React from "react"
import { useState } from "react"
import { navigate } from 'gatsby-link'

import Button from "../Button/Button"
import { ContactStyles } from "./ContactStyles"

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Contact = () => {
  const [state, setState] = useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }
  return (
    <ContactStyles className="section">
      <form name="contact form" method="POST" data-netlify="true" netlify-honeypot="bot-field" action="/thank-you" onSubmit={handleSubmit}>
        <input type="hidden" name="bot-field" value="contact form" />
        <input placeholder="Your name..." type="text" name="name" required onChange={handleChange} />
        <input placeholder="Your email..." type="email" name="email" required onChange={handleChange} />
        <textarea
          placeholder="Your message..."
          name="message"
          rows="5"
          required
          onChange={handleChange}
        ></textarea>
        <div data-netlify-captcha></div>
        <Button text="Send Message" />
      </form>
    </ContactStyles>
  )
}

export default Contact
